@mixin commonInputCust {
  margin: 0 !important;
  font: normal normal normal 15px Muli;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  font-family: 'Muli';
  line-height: 1.5;
}


.insight-main {
  background-color: #ffffff;
    // height: 100%;
    padding: 5% 0% 5% 0;

  @media screen and (max-width: 650px) {
    padding: 6em 0em 5em 0em;
    height: unset;
    

  }

  // height: 100%;
  .insight-label {
    font: normal normal 300 4.5em Proxima Nova;
    color: #4b4b4b;
    opacity: 0.2 !important;
    // margin-left: 10px;
    font-family: 'Muli';
    @media screen and (max-width: 650px) {
      font-size: 50px;
    }
  }
  .notes-col {
    margin-right: 3em;
    @media screen and (max-width: 650px) {
      margin-right: 0em;
    }
  }
  .col-1 {

    // padding-left: 1rem;
    .hasTagDiv {
      .hashTag {
        @include commonInputCust();
        color: #2B64E1;
      }

      .hashTag2 {
        @include commonInputCust();
        color: #4b4b4b;
      }

      .by {
        font: normal normal normal 15px Muli;
        color: #8B8B8B;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 0 !important;
        font-family: 'Muli';
      }
    }

    .note {
      font: normal normal normal 20px Proxima Nova;
      color: #4b4b4b;
      opacity: 1;
      font-family: 'Muli';
    }

    .notes {
      font: normal normal bold 26px/39px Proxima Nova;
      color: #4b4b4b;
      opacity: 1;
      // width: 65%;
      font-family: 'Muli';
      letter-spacing: 0px;
      padding: 1em 0 1em 0;
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }

    .details-notes {
      font: normal normal normal 1.2em/1.7em Proxima Nova;
      letter-spacing: 0px;
      color: #4b4b4b;
      opacity: 1;
      font-family: 'Muli';
      padding-bottom: 2em;
      @media screen and (max-width: 650px) {
        font-size: 1.2em;
      }
    }
  }
}