.Features-main {
  // @media screen and (min-width: 650px) {
  //   height: 100%;
  // }
  // padding: 10%;
  position: relative;
  height: 100%;
  @media screen and (max-width: 650px) {
    height: 90%;
  }
  h1{
    z-index: 999;
    position: absolute;
    top: 12%;
    color: #ffffff;
    // left: 23%;
    text-align: center;
}
.my-form{
  height: 100%;
  width: 100%;
}
  .graphic-icon {
    position: absolute;
    height: 100%;
    left: -15em;
    @media screen and (max-width: 650px) {
      left: -20em;
    }
  }
  .graphic1-icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #ED5D3E 0%, #9E30F5 100%) 0% 0% no-repeat padding-box;
  opacity: 1;

  .feature-row {
    justify-content: space-between;
    .title {
      font: normal normal bold 2em "Proxima Nova";
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      margin: 0;
      font-family: 'Muli';
      @media screen and (max-width: 650px) {
        font-size: 1.5em;
      }
    }

    .message {
      font: normal normal bold 5em "Proxima Nova";
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
      font-family: 'Muli';
      @media screen and (max-width: 650px) {
        font-size: 3em;
      }
    }

    .content {
      font-family: 'Muli' !important;
      font: normal normal normal 1em/27px "Muli";
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }
    .col-2 {
      @media screen and (max-width: 650px) {
       display: flex;
       justify-content: space-between;
      }
    }
    .speakers-details-row {
      justify-content: space-around;
      @media screen and (min-width: 650px) {
        margin-bottom: 4em;
        justify-content: flex-end;
      }
    }
    .event-points {
      margin-right: 6em;
      @media screen and (max-width: 650px) {
        margin-right: 3em;
      }

      .count {
        font: normal normal 300 5em/1em Proxima Nova;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        font-family: 'Muli';
        padding-top: 0.5em;
      }

      .specification {
        font: normal normal normal 1em "Muli";
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin: 0;
        justify-content: center;
        display: flex;
        font-family: 'Muli';
      }
    }

  }
}