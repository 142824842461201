.ant-layout-header {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  // border: 1px solid #D0D0D0;
  opacity: 1;
}

.ant-modal-title {
  color: #4b4b4b;
  font-size: 20px;
  @media screen and (max-width: 650px) {
    font-size: 14px;
  }
}

.ant-modal-body {
  display: flex;
  justify-content: center;

  .btn-label {
    margin: 0 0 0 1em;
  }

  .or {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 1em 0;
  }

  .fill-form {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    width: 70%;
    padding: 0;
    align-items: center;
    font-family: "Muli";
    font-size: 20px;
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }

  .google-login-Modal {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    margin-top: 2em;
    border-radius: 5px !important;
    padding: 0 5px !important;
    font-weight: 600 !important;
    @media screen and (max-width: 650px) {
      padding: 0 0 !important;
    }
    span {
      padding: 10px;
      font-weight: 500;
      display: flex;
      font-family: 'Muli';
      font-size: 20px;
      align-items: center;
      font-weight: 800 !important;
      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
    }
  }
}
.ant-modal-header {
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.ant-modal-content {
  border-radius: 20px;
}
.join-us-google {
  text-align: left;
  font: normal normal 300 18px/19px 'Muli';
  letter-spacing: 0px;
  color: #A7A7A7;
  opacity: 1;
  margin: 0;
}
.join-funda-hands {
  img {
    position: absolute;
    top: -30px;
    left: 10px;
    height: 18%;
  }
}
.modal-outer-border{
  height: 100%;
    width: 103%;
    border: 1px solid #ffff;
    border-radius: 20px;
    position: absolute;
    top: -4px;
    transform: rotate(3deg);
}
.header-main {
  @media screen and (max-width: 650px) {
    display: none;
    &.sticky{
    display: none;

    }
  }
  .logo {
    // border: 1px solid red;
    display: none;

    // filter: invert(70%);

  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 999;

    .logo {
      display: block;

      img {
        width: auto;
        height: 45px;
      }
    }
  }

  display: flex;
  justify-content: center;
  border: 1px solid #f0f0f0;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  .ant-tabs {
    width: 75%;

    @media screen and (max-width: 650px) {
      width: 100%;
    }

    .ant-tabs-nav {

      // padding-left: 6em;
      @media screen and (max-width: 650px) {
        padding-left: 0.5em;
        // width: 90%;
      }
    }

    .ant-tabs-nav-list {
      @media screen and (min-width: 650px) {
        width: 100%;
      }

      .ant-tabs-tab {
        // width: 10%;
        // justify-content: center;
        padding: 20px 0;

        @media screen and (min-width: 650px) {
          width: 10%;
          justify-content: center;
          padding: 20px 0;
        }

        @media screen and (max-width: 375px) {
          margin-left: 10px;
        }
      }
    }

    .ant-tabs-tab-btn {
      outline: none;
      transition: all .3s;
      font: normal normal normal 16px Muli;
      letter-spacing: 0px;
      text-transform: capitalize;
      opacity: 1;
      font-family: 'Muli';
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2B64E1;
    }
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin: 0;
    height: 100%;
  }

  .share-social {
    // width: 30%;
    display: flex;
    align-items: center;

    // border-bottom: 1px solid #f0f0f0;
    // &.sticky {
    //   position: fixed;
    //   top: 0;
    //   width: 100%;
    //   background-color: #ffffff;
    //   z-index: 999;
    // }
    p {
      margin: 0 1em 0 0;
      font: normal normal normal 16px Muli;
      letter-spacing: 0px;
      color: #2F2F2F;
      text-transform: capitalize;
      opacity: 1;
      font-family: 'Muli';

      @media screen and (max-width: 650px) {
        padding: 1em;
        margin: 0em 0em 0 0.5em;
        font: normal normal normal 12px Muli;
        letter-spacing: 0px;
        color: #2F2F2F;
        text-transform: capitalize;
        opacity: 1;
        font-family: 'Muli';
      }
    }

    span {
      margin-left: 1em;
    }

    img {
      margin-left: 1em;

      @media screen and (max-width: 650px) {
        font-size: 0.2em;
        margin: 0 1.2em;
      }
    }

    .google-login {
      padding: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
      height: 1px !important;

      p {
        margin: 0 m !important;
      }

      span {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    .join-community {
      border-radius: 20px;
    background: #2b64e1;
    font-family: 'Muli';
    color: #ffff;
    font-weight: 500;
    }
  }
}

@media screen and (max-width: 650px) {
  .sticky {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;

    .ant-tabs-mobile {
      align-items: center;
    }
  }
}

.modal-main-div {
  @media screen and (max-width: 650px) {
    max-height: 55vh;
    overflow: auto;
  }
}